/**
 * MediaIcons for Footer Component POC
 *
 * @module views/components/MediaIcons
 * @memberof -Common
 */
import './MediaIcons.scss';

import React from 'react';

import PropTypes from 'prop-types';

import { hasItems } from '@ulta/core/utils/array/array';

import NavigationLink from '../NavigationLink/NavigationLink';

/**
 * Represents a MediaIcons component
 *
 * @method
 * @param {MediaIconsProps} props - React properties passed from composition
 * @returns MediaIcons
 */
export const MediaIcons = React.forwardRef( ( props, _ )=>{
  const { modules } = props;

  if( !hasItems( modules ) ){
    return null;
  }

  return (
    <div className='MediaIcons'>
      <ul className='MediaIcons__icons'>
        {
          modules.map( ( { icon, description, action }, index )=>(
            <li className='MediaIcons__iconsItem'
              key={ index }
            >
              <NavigationLink
                action={ action }
                color='black'
                icon={ icon }
                iconSize='xxl'
                description={ description }
              />
            </li>
          ) ) }
      </ul>
    </div>
  );
} );

/**
 * Property type definitions
 * @typedef MediaIconsProps
 * @type {object}
 * @property {array} modules - sets the Social media icons
 */
export const propTypes = {
  modules: PropTypes.array
};


MediaIcons.propTypes = propTypes;
MediaIcons.displayName = 'MediaIcons';

export default MediaIcons;
